<template>
  <dx-util-popup
    ref="membershipPlanPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :width="'600px'"
    height="auto"
    :position="'center'"
    @shown="membershipPlanPopupShown"
    @hidden="membershipPlanPopupHidden"
  >
    <div>
      <dx-util-form ref="membershipPlanFormRef" :form-data="membershipPlanForm" :col-count="1" :show-colon-after-label="false" label-mode="floating" label-location="top">
        <dx-util-item data-field="name">
          <dx-util-required-rule message="Name is required" />
        </dx-util-item>
        <dx-util-item data-field="code">
          <dx-util-required-rule message="Code is required for prepshiphub.com link" />
        </dx-util-item>
        <dx-util-item data-field="description" editor-type="dxTextArea" :editor-options="{ autoResizeEnabled: true }">
          <dx-util-required-rule message="Description is required" />
        </dx-util-item>
        <dx-util-group-item :col-count="2">
          <dx-util-item data-field="monthlyFee" editor-type="dxNumberBox" :editor-options="{min: 0}">
            <dx-util-required-rule message="Fee is required" />
          </dx-util-item>
          <dx-util-item data-field="yearlyDiscount" editor-type="dxNumberBox" :editor-options="{min: 0}" />
          <dx-util-item data-field="itemLimit" editor-type="dxNumberBox" :editor-options="{min: 0}" />
          <dx-util-item data-field="extraItemMonthlyFee" editor-type="dxNumberBox" :editor-options="{min: 0}">
            <dx-util-required-rule v-if="membershipPlanForm.itemLimit > 0" message="Extra item fee is required" />
            <dx-util-range-rule v-if="membershipPlanForm.itemLimit > 0" :min="0.0001" message="Extra item fee is required" />
          </dx-util-item>
          <dx-util-item data-field="storeLimit" editor-type="dxNumberBox" :editor-options="{min: 0}" />
          <dx-util-item data-field="extraStoreMonthlyFee" editor-type="dxNumberBox" :editor-options="{min: 0}">
            <dx-util-required-rule v-if="membershipPlanForm.storeLimit > 0" message="Extra item fee is required" />
            <dx-util-range-rule v-if="membershipPlanForm.storeLimit > 0" :min="0.0001" message="Extra item fee is required" />
          </dx-util-item>
          <dx-util-item data-field="userLimit" editor-type="dxNumberBox" :editor-options="{min: 0}" />
          <dx-util-item data-field="extraUserMonthlyFee" editor-type="dxNumberBox" :editor-options="{min: 0}">
            <dx-util-required-rule v-if="membershipPlanForm.userLimit > 0" message="Extra item fee is required" />
            <dx-util-range-rule v-if="membershipPlanForm.userLimit > 0" :min="0.0001" message="Extra item fee is required" />
          </dx-util-item>
          <dx-util-item data-field="freeTrialDay" editor-type="dxNumberBox" :editor-options="{min: 0}" />
          <dx-util-item data-field="memberAudienceType" editor-type="dxSelectBox" :editor-options="audienceTypeEditorOptions">
            <dx-util-required-rule message="Group type is required" />
          </dx-util-item>
          <dx-util-item data-field="isThereFreeTrial" editor-type="dxCheckBox" />
          <dx-util-item data-field="isActive" editor-type="dxCheckBox" />
        </dx-util-group-item>
        <dx-util-item template="iconSelectTemplate" />
        <template #iconSelectTemplate>
          <dx-util-scroll-view height="150px">
            <div class="d-flex flex-wrap">
            <div v-for="icon in membershipPlanIcons" :key="icon.name"
              class="p-half rounded"
              style="padding: 2px;" :class="icon === membershipPlanForm.iconUrl ? 'bg-success' : ''"
              @click="setIcon(icon)"
            >
              <p-icon :name="icon" />
            </div>
          </div>
          </dx-util-scroll-view>
        </template>
      </dx-util-form>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import membershipPlanService from '@/http/requests/membership/plan'
import { getAudienceTypeList } from '@/enums/membershipAudienceType.enum.js'
import MembershipPlanIcons from './membership-plan-icon'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    membershipPlanData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
      membershipPlanIcons: MembershipPlanIcons,
      audienceTypes: getAudienceTypeList(),
      membershipPlanForm: {
        name: '',
        code: '',
        description: '',
        iconUrl: '',
        monthlyFee: 0,
        itemLimit: 0,
        extraItemMonthlyFee: 0,
        storeLimit: 0,
        extraStoreMonthlyFee: 0,
        userLimit: 0,
        extraUserMonthlyFee: 0,
        memberAudienceType: 'WAREHOUSE',
        isThereFreeTrial: true,
        freeTrialDay: 30,
        yearlyDiscount: 0,
        isActive: true,
      },
    }
  },
  computed: {
    membershipPlanPopup() {
      return this.$refs.membershipPlanPopupRef.instance
    },
    audienceTypeEditorOptions() {
      return {
        value: this.membershipPlanForm.memberAudienceType,
        dataSource: this.audienceTypes,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.membershipPlanPopup.show()
      },
    },
    membershipPlanData: {
      immediate: true,
      handler() {
        if (this.membershipPlanData.id) {
          this.membershipPlanForm.id = this.membershipPlanData.id
          this.membershipPlanForm.name = this.membershipPlanData.name
          this.membershipPlanForm.code = this.membershipPlanData.code
          this.membershipPlanForm.description = this.membershipPlanData.description
          this.membershipPlanForm.iconUrl = this.membershipPlanData.iconUrl
          this.membershipPlanForm.monthlyFee = this.membershipPlanData.monthlyFee
          this.membershipPlanForm.storeLimit = this.membershipPlanData.storeLimit
          this.membershipPlanForm.extraStoreMonthlyFee = this.membershipPlanData.extraStoreMonthlyFee
          this.membershipPlanForm.userLimit = this.membershipPlanData.userLimit
          this.membershipPlanForm.extraUserMonthlyFee = this.membershipPlanData.extraUserMonthlyFee
          this.membershipPlanForm.itemLimit = this.membershipPlanData.itemLimit
          this.membershipPlanForm.extraItemMonthlyFee = this.membershipPlanData.extraItemMonthlyFee
          this.membershipPlanForm.memberAudienceType = this.membershipPlanData.memberAudienceType
          this.membershipPlanForm.isThereFreeTrial = this.membershipPlanData.isThereFreeTrial
          this.membershipPlanForm.freeTrialDay = this.membershipPlanData.freeTrialDay
          this.membershipPlanForm.yearlyDiscount = this.membershipPlanData.yearlyDiscount
        }
      },
    },
  },
  methods: {
    membershipPlanPopupShown() {
      this.popupTitle = !this.membershipPlanData?.id ? 'Add New Plan' : `Edit Plan of ${this.membershipPlanData?.name}`
    },
    membershipPlanPopupHidden() {
    // Clear form when popup is closed
      this.membershipPlanForm.id = null
      this.membershipPlanForm.name = ''
      this.membershipPlanForm.code = ''
      this.membershipPlanForm.description = ''
      this.membershipPlanForm.iconUrl = ''
      this.membershipPlanForm.monthlyFee = 0
      this.membershipPlanForm.memberAudienceType = 'WAREHOUSE'
      this.membershipPlanForm.isThereFreeTrial = true
      this.membershipPlanForm.freeTrialDay = 0
      this.membershipPlanForm.storeLimit = 0
      this.membershipPlanForm.extraStoreMonthlyFee = 0
      this.membershipPlanForm.userLimit = 0
      this.membershipPlanForm.extraUserMonthlyFee = 0
      this.membershipPlanForm.itemLimit = 0
      this.membershipPlanForm.extraItemMonthlyFee = 0
      this.membershipPlanForm.yearlyDiscount = 0
      this.$emit('emit-form-closed')
    },
    closePopup() {
      this.membershipPlanPopup.hide()
    },
    handleSubmit() {
      const form = this.$refs.membershipPlanFormRef.instance
      const formValidate = form.validate()
      const formId = this.membershipPlanForm.id
      if (formValidate.isValid) {
        if (formId && formId !== 0) {
          membershipPlanService.update(this.membershipPlanForm)
          this.$emit('emit-form-updated')
        } else {
          delete form.id
          membershipPlanService.create(this.membershipPlanForm)
          this.$emit('emit-form-created')
        }
        this.closePopup()
      }
    },
    setIcon(iconName) {
      this.membershipPlanForm.iconUrl = iconName
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
@/enums/membershipSubscriptionStatus.enum
