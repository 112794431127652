const MembershipPlanIcons = [
  'feather-activity',
  'feather-airplay',
  'feather-alert-circle',
  'feather-alert-octagon',
  'feather-alert-triangle',
  'feather-align-center',
  'feather-align-justify',
  'feather-align-left',
  'feather-align-right',
  'feather-anchor',
  'feather-aperture',
  'feather-archive',
  'feather-arrow-down-circle',
  'feather-award',
  'feather-bell',
  'feather-bluetooth',
  'feather-bold',
  'feather-book-open',
  'feather-book',
  'feather-bookmark',
  'feather-box',
  'feather-briefcase',
  'feather-calendar',
  'feather-camera',
  'feather-chrome',
  'feather-circle',
  'feather-clock',
  'feather-cloud-drizzle',
  'feather-cloud-lightning',
  'feather-cloud-off',
  'feather-cloud-rain',
  'feather-cloud-snow',
  'feather-cloud',
  'feather-code',
  'feather-codepen',
  'feather-codesandbox',
  'feather-coffee',
  'feather-columns',
  'feather-command',
  'feather-compass',
  'feather-copy',
  'feather-cpu',
  'feather-credit-card',
  'feather-crop',
  'feather-crosshair',
  'feather-database',
  'feather-download-cloud',
  'feather-dribbble',
  'feather-droplet',
  'feather-eye',
  'feather-feather',
  'feather-figma',
  'feather-frown',
  'feather-gift',
  'feather-git-branch',
  'feather-git-commit',
  'feather-git-merge',
  'feather-git-pull-request',
  'feather-github',
  'feather-gitlab',
  'feather-globe',
  'feather-grid',
  'feather-hard-drive',
  'feather-hash',
  'feather-headphones',
  'feather-heart',
  'feather-help-circle',
  'feather-hexagon',
  'feather-home',
  'feather-image',
  'feather-inbox',
  'feather-info',
  'feather-instagram',
  'feather-italic',
  'feather-key',
  'feather-layers',
  'feather-layout',
  'feather-life-buoy',
  'feather-link-2',
  'feather-link',
  'feather-linkedin',
  'feather-list',
  'feather-loader',
  'feather-lock',
  'feather-log-in',
  'feather-log-out',
  'feather-mail',
  'feather-map-pin',
  'feather-map',
  'feather-maximize-2',
  'feather-maximize',
  'feather-meh',
  'feather-menu',
  'feather-message-circle',
  'feather-message-square',
  'feather-mic-off',
  'feather-mic',
  'feather-minimize-2',
  'feather-minimize',
  'feather-minus-circle',
  'feather-minus-square',
  'feather-minus',
  'feather-monitor',
  'feather-moon',
  'feather-more-horizontal',
  'feather-more-vertical',
  'feather-mouse-pointer',
  'feather-move',
  'feather-music',
  'feather-navigation-2',
  'feather-navigation',
  'feather-octagon',
  'feather-package',
  'feather-paperclip',
  'feather-pause-circle',
  'feather-pause',
  'feather-pen-tool',
  'feather-percent',
  'feather-phone-call',
  'feather-phone-forwarded',
  'feather-phone-incoming',
  'feather-phone-missed',
  'feather-phone-off',
  'feather-phone-outgoing',
  'feather-phone',
  'feather-pie-chart',
  'feather-play-circle',
  'feather-play',
  'feather-plus-circle',
  'feather-plus-square',
  'feather-plus',
  'feather-pocket',
  'feather-power',
  'feather-printer',
  'feather-radio',
  'feather-refresh-ccw',
  'feather-refresh-cw',
  'feather-repeat',
  'feather-rewind',
  'feather-rotate-ccw',
  'feather-rotate-cw',
  'feather-rss',
  'feather-save',
  'feather-scissors',
  'feather-search',
  'feather-send',
  'feather-server',
  'feather-settings',
  'feather-share-2',
  'feather-share',
  'feather-shield-off',
  'feather-shield',
  'feather-shopping-bag',
  'feather-shopping-cart',
  'feather-shuffle',
  'feather-sidebar',
  'feather-skip-back',
  'feather-skip-forward',
  'feather-slack',
  'feather-slash',
  'feather-sliders',
  'feather-smartphone',
  'feather-smile',
  'feather-speaker',
  'feather-square',
  'feather-star',
  'feather-stop-circle',
  'feather-sun',
  'feather-sunrise',
  'feather-sunset',
  'feather-table',
  'feather-tablet',
  'feather-tag',
  'feather-target',
  'feather-terminal',
  'feather-thermometer',
  'feather-thumbs-down',
  'feather-thumbs-up',
  'feather-toggle-left',
  'feather-toggle-right',
  'feather-tool',
  'feather-trash-2',
  'feather-trash',
  'feather-trello',
  'feather-trending-down',
  'feather-trending-up',
  'feather-triangle',
  'feather-truck',
  'feather-tv',
  'feather-twitch',
  'feather-twitter',
  'feather-type',
  'feather-umbrella',
  'feather-underline',
  'feather-unlock',
  'feather-upload-cloud',
  'feather-upload',
  'feather-user-check',
  'feather-user-minus',
  'feather-user-plus',
  'feather-user-x',
  'feather-user',
  'feather-users',
  'feather-video-off',
  'feather-video',
  'feather-voicemail',
  'feather-volume-1',
  'feather-volume-2',
  'feather-volume-x',
  'feather-volume',
  'feather-watch',
  'feather-wifi-off',
  'feather-wifi',
  'feather-wind',
  'feather-x-circle',
  'feather-x-octagon',
  'feather-x-square',
  'feather-x',
  'feather-youtube',
  'feather-zap-off',
  'feather-zap',
  'feather-zoom-in',
  'feather-zoom-out',
  'psh-account',
  'psh-alarm',
  'psh-banned',
  'psh-barcode-3',
  'psh-barcode',
  'psh-bill',
  'psh-box',
  'psh-box2',
  'psh-box3',
  'psh-cancelled',
  'psh-cargo-box',
  'psh-cargo',
  'psh-cargo2',
  'psh-cargo3',
  'psh-cargo4',
  'psh-cargo5',
  'psh-cash-on-delivery',
  'psh-cold-storage',
  'psh-container',
  'psh-container2',
  'psh-control',
  'psh-conveyor-2',
  'psh-conveyor-belt-2',
  'psh-conveyor-belt',
  'psh-conveyor',
  'psh-crane-2',
  'psh-crane-machine',
  'psh-crane',
  'psh-currency',
  'psh-customer-service-2',
  'psh-customer-service',
  'psh-customer',
  'psh-dashboard',
  'psh-dashboard3',
  'psh-delivered-parcel',
  'psh-delivery-box-2',
  'psh-delivery-box-3',
  'psh-delivery-box',
  'psh-delivery-schedule',
  'psh-delivery-service',
  'psh-delivery-time',
  'psh-delivery-truck-2',
  'psh-delivery-truck-3',
  'psh-delivery-truck',
  'psh-delivery',
  'psh-delivery1',
  'psh-delivery2',
  'psh-delivery3',
  'psh-delivery4',
  'psh-delivery5',
  'psh-delivery6',
  'psh-delivery7',
  'psh-delivery8',
  'psh-delivery9',
  'psh-discussion',
  'psh-dispatch',
  'psh-distribution-2',
  'psh-distribution',
  'psh-document',
  'psh-door-delivery-2',
  'psh-door-delivery',
  'psh-drone-delivery',
  'psh-drop-shipping',
  'psh-efficiency',
  'psh-exchange',
  'psh-export',
  'psh-fast-delivery',
  'psh-fifo',
  'psh-flame',
  'psh-food',
  'psh-forklift-2',
  'psh-forklift-3',
  'psh-forklift-truck',
  'psh-forklift',
  'psh-fragile-2',
  'psh-fragile',
  'psh-freight',
  'psh-fuel',
  'psh-glass',
  'psh-global',
  'psh-goods',
  'psh-home-delivery',
  'psh-home-delivery3',
  'psh-hook',
  'psh-hours',
  'psh-import',
  'psh-inbound',
  'psh-inbound2',
  'psh-inbound3',
  'psh-industrial-park',
  'psh-industrial',
  'psh-inspection',
  'psh-interchange',
  'psh-inventory',
  'psh-inventory1',
  'psh-inventory4',
  'psh-item-tracking2',
  'psh-item-tracking3',
  'psh-just-in-time',
  'psh-keep-dry',
  'psh-labeling',
  'psh-ladder',
  'psh-laptop-computer',
  'psh-lead-time',
  'psh-leader-board',
  'psh-lift-2',
  'psh-lift',
  'psh-lifter-2',
  'psh-lifter',
  'psh-lifting',
  'psh-limited-stock',
  'psh-list',
  'psh-load',
  'psh-loading',
  'psh-location-2',
  'psh-location',
  'psh-lock',
  'psh-locked-delivery',
  'psh-logistic-2',
  'psh-logistic-3',
  'psh-logistic',
  'psh-logistics-delivery',
  'psh-management',
  'psh-management2',
  'psh-manufacture',
  'psh-marketing-2',
  'psh-marketing',
  'psh-mass-production',
  'psh-metrics',
  'psh-mobile',
  'psh-note',
  'psh-outbound',
  'psh-outbound2',
  'psh-package-1',
  'psh-package-2',
  'psh-package-3',
  'psh-package-4',
  'psh-package-5',
  'psh-package-6',
  'psh-package-box',
  'psh-package',
  'psh-packaging-2',
  'psh-packaging',
  'psh-pallet',
  'psh-parachute',
  'psh-parcel-1',
  'psh-parcel-2',
  'psh-parcel-3',
  'psh-parcel-4',
  'psh-parcel-5',
  'psh-parcel-6',
  'psh-parcel-size',
  'psh-parcel-weight',
  'psh-parcel',
  'psh-payment',
  'psh-planning',
  'psh-postmark',
  'psh-power-housing',
  'psh-profit',
  'psh-protection',
  'psh-qr-code-2',
  'psh-qr-code',
  'psh-qr-scan',
  'psh-receive-2',
  'psh-receive',
  'psh-received',
  'psh-recycle',
  'psh-reports',
  'psh-return',
  'psh-scale',
  'psh-scanner',
  'psh-scanning-2',
  'psh-scanning',
  'psh-screen-2',
  'psh-screen',
  'psh-seaport',
  'psh-search-2',
  'psh-search-3',
  'psh-search',
  'psh-shelves-2',
  'psh-shelves',
  'psh-shipment',
  'psh-shipping-box',
  'psh-side-up',
  'psh-sorted',
  'psh-sorting',
  'psh-stacker',
  'psh-stock-2',
  'psh-stock-3',
  'psh-stock-4',
  'psh-stock-5',
  'psh-stock',
  'psh-storehouse-2',
  'psh-storehouse',
  'psh-supplier',
  'psh-supply-chain-2',
  'psh-supply-chain',
  'psh-supply',
  'psh-thumb-down-2',
  'psh-thumb-down',
  'psh-thumb-up-2',
  'psh-thumb-up',
  'psh-toolbox',
  'psh-tracking-2',
  'psh-tracking-3',
  'psh-tracking',
  'psh-trade',
  'psh-train',
  'psh-transfer',
  'psh-transmitter',
  'psh-trolley-1',
  'psh-trolley-2',
  'psh-trolley-3',
  'psh-trolley-4',
  'psh-truck-2',
  'psh-truck',
  'psh-trucks',
  'psh-umbrella',
  'psh-unload',
  'psh-user',
  'psh-verified',
  'psh-warehouse2',
  'psh-warehouse3',
  'psh-warehouse4',
  'psh-warehouse5',
  'psh-warehouse6',
  'psh-warning',
  'psh-website',
  'psh-weight-scale-2',
  'psh-weight-scale',
  'psh-wholesaler',
  'psh-wooden-box-2',
  'psh-yacht',
]

export default MembershipPlanIcons
